.fade {
  //   height: 100%;

  &.enter {
    opacity: 0;
  }

  &.enterActive,
  &.enterDone {
    opacity: 1;
    transition: opacity var(--pageTransitionTime);
  }

  &.exit {
    opacity: 0;
  }

  &.exitActive,
  &.exitDone {
    opacity: 0;
    // removing this transition because NextJS removes styles of previous page too early so we don't have time to animate out
    // transition: opacity var(--pageTransitionTime);
  }
}
