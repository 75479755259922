:root {
  --standard-cols: 16;
  --standard-gap: #{rem(20)};
  --page-gutter: 20px;

  // default settings for padding/margins
  --space-small: #{rem(10)};
  --space: #{rem(12)};
  --space-medium: #{rem(24)};
  --space-large: #{rem(40)};
  --space-x-large: #{rem(48)};
  --space-xx-large: #{rem(52)};
  // we need this to be a var as we use it in some diff places
  // has to be MS
  --pageTransitionTime: 1000ms;

  @include md {
    --page-gutter: #{rem(50)};
    --space-x-large: #{rem(64)};
    --space-xx-large: #{rem(100)};
  }
}
