// Base styles that aren't a component, a utility, a reset thing, etc...

html {
  background: black;
  @media (prefers-reduced-motion: no-preference) {
    // scroll-behavior: smooth;
  }
  @media screen and (prefers-reduced-motion: reduce), (update: slow) {
    * {
      animation-duration: 0.001ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.001ms !important;
    }
  }
}
body {
  opacity: 0;
  transition: opacity 0.1s;
  &.is-fonts-loaded {
    opacity: 1;
  }
}
abbr {
  text-decoration: none;
  border-bottom: none;
}
