@font-face {
  font-family: 'Moderat-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/Moderat-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'PPEditorialNew-Ultralight';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/PPEditorialNew-Ultralight.woff2') format('woff2');
}

@font-face {
  font-family: 'PPEditorialNew-Ultralight';
  font-style: italic;
  font-weight: normal;
  src: url('./fonts/PPEditorialNew-UltralightItalic.woff2') format('woff2');
}
