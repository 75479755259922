@import '../../scss/components_base';

.container {
  width: 100%;
  height: 100%;
  svg {
    path {
      fill: var(--logo-color);
    }
  }
}
.desktop {
  display: none;
  @include md {
    display: block;
  }
}

.mobile {
  display: block;
  @include md {
    display: none;
  }
}
