// =============================================================================
// Custom properties (variables) for custom colors, including the brand colors,
// primary and secondary palettes.
// =============================================================================

:root {
  --color-primary: white;
  --color-rollover: #707070;
 

  --logo-color: white;
}
