@import '../../scss/components_base';

.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background-color: var(--sticky-bg);
  z-index: 100;
  transform: translateY(-200px);
  transition: transform 0.1s;
  @include md {
    // display: none;
    height: 100px;
  }
}
.containerIsVisible {
  transition: transform 0.51s;
  transform: translateY(0);
}
