@import '../../scss/components_base';

.logo {
  display: block;
  width: 90px;
  margin-top: 20px;
  pointer-events: all;
  @include md {
    margin-top: rem(45);
    width: rem(250);
  }
}
