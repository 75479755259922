@import '../../scss/components_base';

.menuContainer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  color: white;
}
.bg {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: black;
  width: 100%;
  min-height: 100%;
  z-index: 1;
}
.list {
  @include list-unstyled;
  @include gutter;
  padding-top: 50px;
  flex: 1;
  position: relative;
  z-index: 2;
  @include md {
    padding-top: var(--page-gutter);
  }
}
.listItem {
}

.listItemLink {
  @include h1;
  @include unstyled-link();
  color: white;
  &:hover {
    color: var(--color-rollover);
  }
}
.listItemLinkActive {
  color: var(--color-rollover);
}
.footer {
  @include gutter;
  display: block;
  position: relative;
  z-index: 2;
  margin-top: rem(50);
  margin-bottom: rem(50);

  @include md {
    @include grid-standard;
    grid-column-start: 1;
    grid-column-end: -1;
    grid-row-start: 1;
    grid-template-areas:
      'l l l l l l . e e e x x x s s s'
      'c c c c c c c c c c c c c c c c';
  }
}
.tagLine {
  grid-area: l;
  padding-bottom: var(--space);
}
.email {
  grid-area: e;
  padding-bottom: var(--space);
}
.address {
  grid-area: x;
  padding-bottom: var(--space);
}
.subscribe {
  grid-area: s;
  padding-bottom: var(--space);
}
.copyright {
  @include body-small;
  grid-area: c;
  // margin-top: rem(170);
  color: #444444;
  margin-top: 16px;
  @include md {
    margin-top: 0;
  }
}
.copyrightLink {
  color: #444444;
  &:hover {
    color: white;
  }
}
