@import '../../scss/components_base';

.stickyScrollContainer {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  // --logo-color: white;
  @include md {
    width: 100%;
  }
}
.stickyScrollToggleContainer {
  // posit
  order: 2;
  position: absolute;
  top: 25px;
  right: 0;
  @include gutter;
  @include md {
    top: var(--page-gutter);
    right: 0px;
    padding-left: var(--page-gutter);
    padding-right: var(--page-gutter);
  }
}
.stickyScrollLogoContainer {
  order: 1;
  @include md {
    // display: none;
  }
}

.container {
  // display: flex;
  // flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // height: 600px;
  pointer-events: none;
}
.logoContainer {
  // pointer-events: all;
  position: relative;
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-300px);
  transition: transform 0.1s 0s;
  transition-timing-function: ease-in-out;
}
.logoContainerShow {
  // this delay is set because of the transition delays for pages

  transition: transform 0.5s var(--pageTransitionTime);
  transform: translateY(0);
}
.logoIsWhite {
  --logo-color: #ffffff;
}
.toggleContainer {
  pointer-events: all;
  position: fixed;
  position: absolute;
  top: var(--page-gutter);
  right: 0px;
  @include gutter;
  align-self: flex-end;
  z-index: 102;
}
.menuContainer {
  background: black;
  min-height: 200%;
}
.overlay {
  background-color: transparent;
}
