.dark {
  color: white;
  --logo-color: white;
  --sticky-bg: black;
}
.light {
  background: white;
  --logo-color: black;
  --sticky-bg: white;
  color: black;
}
