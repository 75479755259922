@import '../../scss/components_base';

.container {
  // position: relative;
  height: 100%;
  position: fixed;
  width: 100%;
  background: black;
  color: white;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    path {
      fill: white;
    }
  }
}

.logoContainer {
  @include gutter;
  width: 90%;
  flex: 1;
  opacity: 0;
  transform: translateY(100px);
  @include md {
    width: rem(500);
  }
}
.intro {
  @include gutter;
  flex: 1;
  opacity: 0;
  justify-self: flex-start;
  transform: translateY(-100px);
}
