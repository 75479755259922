.video-js {
  &:focus,
  &:hover {
    .vjs-big-play-button {
      background-color: transparent;
      transition: none;
    }
  }
  .vjs-big-play-button {
    // dont give zondex as it comes on top of the poster
    // z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    font-size: rem(100);
    background-color: transparent;
    transition: none;

    .vjs-icon-placeholder::before {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .vjs-poster.element-child {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .vjs-big-play-button {
  }

  .vjs-control-bar {
    background-color: transparent;
  }

  .vjs-slider {
    background-color: transparentize(#fff, 0.5);
  }

  .vjs-load-progress {
    background-color: transparentize(#fff, 0.5);

    div {
      background-color: transparentize(#fff, 0.5);
    }
  }

  .vjs-button > .vjs-icon-placeholder:before {
    font-size: 20px;
    line-height: 1.67;
  }
}
