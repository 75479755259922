@import '../../scss/components_base';

.container {
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  // color: white;
  // background: black;
  padding-top: rem(70);

  @include md {
    padding-bottom: 0;
  }
}
.logoContainer {
  margin-bottom: rem(50);
  width: rem(264);
}
.categoryContainer {
  margin-top: rem(70);
}
